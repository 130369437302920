<template>
  <div class="flex flex-col">
    <div id="hardware-category-products" class="hardware-category-products-container">

      <!-- BREADCRUMB -->
      <breadcrumb-base :breadcrumb="breadcrumb" :title="'Hardware'">
      </breadcrumb-base>

      <!--    Empty List State -->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="categories.length === 0 && !errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
            <!--               class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="flex flex-wrap gap-x-6 gap-y-6">
          <a
            v-for="(cat, index) of categories"
            @click="openCategoryProductsPage(cat)"
            class="flex cursor-pointer flex-row space-x-2 justify-between p-4 min-w-[250px] max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <p
              class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {{ cat.title }}
            </p>
          </a>

        </div>
      </transition>
    </div>

    <div style="position: absolute; bottom: 5px;">
      <p style="font-size: 8px;">* Zuzüglich Postgebühren und Steuer</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HardwareCategories",
  data() {
    return {
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Hardware', i18n: 'Categories', active: true},
      ],
    }
  },
  computed: {
    categories() {
      return this.$store.state.hardware.categories
    },
  },
  methods: {
    openCategoryProductsPage(cat) {
      this.$router.push({name: 'hardware-category-products', params: {categoryId: cat.id}})
    },
    fetchCategories() {
      this.$vs.loading()
      this.$store.dispatch('hardware/fetchHardwareCategories', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchCategories();
  },
}
</script>

<style scoped>

</style>
